$red: #e31f13;
$darkblue: #003763;
$brown: #ac8433;
$lightblue: #6bbbde;
$lightgrey: #f6f6f6;
$verylightgrey: #f1f1f1;
$midgrey: #cccccc;
$darkgrey: #e5e5e5;
$verydarkgrey: #cbcbcb;
$shopgrey: #cccccc;
$pagegrey: #e6e6e6;

$grid-breakpoints: (
  xxxs: 0,
  xxs: 375px,
  xs: 568px,
  sm: 667px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  xxxs: 375px,
  xxs: 568px,
  xs: 667px,
  sm: 768px,
  md: 992px,
  lg: 1200px,
  xl: 1200px
);
