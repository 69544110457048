@import 'variables';

button {
  -webkit-appearance: none;
  border: 0;
  outline: none;
  background-color: transparent;
  padding: 0;
  &:focus {
    outline: none;
  }
}

.btn,
a.btn {
  font-size: 12px;
  text-transform: uppercase;
  height: 55px;
  text-align: center;
  padding: 0 2rem;
  background-color: $brown;
  color: white;
  border: 0;
  text-decoration: none;
  -webkit-appearance: none;
  min-width: 200px;
  white-space: nowrap;
  user-select: none;
  &.btn-dark-blue {
    background-color: $darkblue;
  }

  &.btn-light-blue {
    background-color: $lightblue;
  }

  &.btn-red {
    background-color: $red;
  }

  &.center {
    display: block;
    margin: 0 auto;
  }

  &.btn-icon {
    min-width: auto;
    padding: 0;
    .icon {
      fill: white;
      width: 20px;
      height: 20px;
      @include media-breakpoint-up(md) {
        width: 30px;
        height: 30px;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
  }
}

a.btn {
  display: inline-block !important;
  vertical-align: middle;
  line-height: 55px;
}

.btn-group {
  display: flex;
  flex-direction: column;
  margin: 0 -15px;
  justify-content: center;
  .btn {
    margin: 0 15px 15px;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
}
