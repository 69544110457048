@import 'variables';
@import 'unit';

@font-face {
  font-family: 'bignoodleregular';
  src: url('/assets/fonts/big_noodle_titling-webfont.woff2') format('woff2'),
    url('/assets/fonts/big_noodle_titling-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bignoodleoblique';
  src: url('/assets/fonts/big_noodle_titling_oblique-webfont.woff2')
      format('woff2'),
    url('/assets/fonts/big_noodle_titling_oblique-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

h1,
h2,
h3,
.h3,
h4,
h5,
.h5,
h6,
.h6 {
  font-family: 'bignoodleregular';
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
  font-weight: normal;
}

h1,
.h1 {
  font-size: 40px;
  @include media-breakpoint-up(md) {
    font-size: 60px;
  }
  &.large,
  .large {
    font-size: 80px;
    @include media-breakpoint-up(md) {
      font-size: 120px;
    }
  }
  .large {
    display: block;
  }
  &.white {
    color: white;
  }
}

h2,
.h2 {
  font-size: 40px;
  @include media-breakpoint-up(md) {
    font-size: 70px;
  }
}

h3,
.h3 {
  font-size: 30px;
  @include media-breakpoint-up(md) {
    font-size: 60px;
  }
}

h4,
.h4 {
  font-size: 30px;
  @include media-breakpoint-up(md) {
    font-size: 40px;
  }
}

h5,
.h5 {
  font-size: 30px;
}

h6,
.h6 {
  font-size: 25px;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-danger {
  color: $red;
}

.title {
  font-family: 'bignoodleregular';
  text-transform: uppercase;
  margin: 0;
  line-height: 1;
  font-weight: normal;
}
p {
  a {
    color: inherit;
    border-bottom: 2px solid black;
    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

a {
  color: $darkblue;
  &:hover {
    color: $darkblue;
  }
}
