.form-block {
  background-color: $darkgrey;
  padding: 50px 0;
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  .container {
    max-width: 1000px;
  }
  .h1 {
    font-size: 60px;
    @include media-breakpoint-up(md) {
      font-size: 80px;
    }
    .large {
      font-size: 60px;
      @include media-breakpoint-up(md) {
        font-size: 130px;
      }
    }
  }

  h2 {
    font-size: 40px;
    margin-bottom: 1rem;
    @include media-breakpoint-up(md) {
      font-size: 60px;
    }
  }
}

input {
  border: 0;
  margin: 0;
  padding: 5px;
  outline: 0;
  border-radius: 0;
  -webkit-appearance: none;
  font-size: 16px;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
  &:disabled {
    background-color: white;
  }
  &.chunky {
    height: 55px;
    padding: 0.5em;
    font-size: 16px;
    width: 100%;
  }
}

select {
  font-size: 16px;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

.dropdown-select {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  select {
    width: 100%;
    background-color: white;
    padding: 1em;
    padding-right: 40px;
    border: 0;
    margin: 0;
    border-radius: 0;
    text-indent: 0.01px;
    text-overflow: '';
    /*Hiding the select arrow for firefox*/
    -moz-appearance: none;
    /*Hiding the select arrow for chrome*/
    -webkit-appearance: none;
    /*Hiding the select arrow default implementation*/
    appearance: none;
    outline: none;
    text-transform: uppercase;
    &::-ms-expand {
      display: none;
    }
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    pointer-events: none;
  }
  &::after {
    /*  Custom dropdown arrow */
    content: '';
    width: 20px;
    background: url('/assets/svg/arrow-select.svg');
    background-repeat: no-repeat;
    background-size: 20px 10px;
    height: 1em;
    line-height: 1;
    right: 10px;
    top: 50%;
    margin-top: -5px;
  }
  &::before {
    /*  Custom dropdown arrow cover */
    width: 40px;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 0;
    background-color: white;
  }
  &.chunky {
    select {
      height: 55px;
      padding: 0.5em;
      font-size: 16px;
    }
    &::after {
      width: 18px;
      background: url('/assets/svg/arrow-drop-menu-icon.svg');
      background-repeat: no-repeat;
      background-size: 18px 11px;
      right: 20px;
    }
  }
}

.checkbox {
  position: relative;
  input[type='checkbox'] {
    position: absolute; // take it out of document flow
    opacity: 0; // hide it
    & + label {
      cursor: pointer;
      padding: 0;
      padding-left: 40px;
    }

    // Box hover
    &:hover + label:before {
      background: $lightblue;
    }

    // Box checked
    &:checked + label:before {
      background: $lightblue;
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: '';
      background-image: url('/assets/svg/tick.svg');
      background-size: 24px 18px;
      background-repeat: no-repeat;
      position: absolute;
      left: 3px;
      top: 6px;
      width: 24px;
      height: 18px;
    }
  }
  label:before {
    content: '';
    background: $white;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    border: 2px solid $darkgrey;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
  }
}

.radio {
  &__wrapper {
    position: relative;
    margin-bottom: 1rem;
    margin-top: 1rem;
    label {
      display: block;
      padding: 0 0 0 38px;
      cursor: pointer;
    }
    input {
      height: 30px;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 30px;
      cursor: pointer;
    }

    input + label::before {
      border: 2px solid $lightblue;
      content: '';
      height: 30px;
      left: 0;
      position: absolute;
      top: 0;
      width: 30px;
      border-radius: 50%;
    }

    input + label::after {
      content: '';
      opacity: 0;
      border: 7px solid $lightblue;
      border-radius: 50%;
      position: absolute;
      left: 8px;
      top: 8px;
      transition: opacity 0.2s ease-in-out;
    }
    input:checked + label::after {
      opacity: 1;
    }

    input:focus + label::before {
      box-shadow: 0 0 0 3px $verydarkgrey;
      outline: 3px solid transparent; /* For Windows high contrast mode. */
    }
  }
}

.form-field {
  margin-bottom: 20px;
  display: block;
  @include media-breakpoint-up(md) {
    margin-bottom: 20px;
  }
}

.form-field-wrapper {
  border: 2px solid $darkgrey;
  background-color: white;
  display: block;
  position: relative;
  overflow: hidden;
}

.field-wrapper {
  border: 2px solid $darkgrey;
  background-color: white;
  display: block;
  position: relative;
  overflow: hidden;
  input,
  textarea,
  .input-element {
    padding: 1em;
  }
}

.field-label {
  text-transform: uppercase;
  font-size: 15px;
  line-height: 1;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

.ng-invalid.ng-touched,
.has-error {
  > .form-field-wrapper {
    border-color: $red;
  }
  > .field-wrapper {
    border-color: $red;
  }
  > .field-label {
    color: $red;
  }
}

.input-element {
  font: inherit;
  background: 0 0;
  color: currentColor;
  border: none;
  outline: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 100%;
  vertical-align: bottom;
  text-align: inherit;
  height: auto;
  font-size: 16px;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}
