nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    li {
      a {
        text-decoration: none;
        color: initial;
        text-transform: uppercase;
        display: block;
      }
      a:hover {
        text-decoration: none;
        color: initial;
      }
    }
  }
}
