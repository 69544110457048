/* You can add global styles to this file, and also import other style files */
@import 'node_modules/bootstrap/scss/bootstrap-reboot';
@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins/_breakpoints';
@import 'node_modules/bootstrap/scss/mixins/_grid';
@import 'variables';
@import 'button';
@import 'typography';
@import 'form';
@import 'unit';
@import 'swiper-style';
@import 'menu';
@import 'ng-select';
@import 'toastr';

.modal-open {
  overflow: hidden;
}

html,
body {
  height: 100%;
}

body {
  font-family: transat-text, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 15px;
  color: black;
  @include media-breakpoint-up(md) {
    font-size: 18px;
  }
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

.container {
  @include make-container();
  @include make-container-max-widths($container-max-widths);
}

.page {
  position: relative;
  padding-top: 40px;
  padding-bottom: 30px;
  @include media-breakpoint-up(md) {
    padding-top: 85px;
    padding-bottom: 55px;
  }
  &.with-breadcrumb {
    padding-top: 12px;
    @include media-breakpoint-up(md) {
      padding-top: 33px;
    }
  }
}

.row {
  @include make-row();
}
